.container {
  background-color: white;
  width: 100%;
  height: 90vh;
  overflow-y: scroll;
  padding: 5rem;
}

.row {
  row-gap: 2rem;
}

.View,
h1 {
  background-color: white;
  /* color: white; */
  padding: 1rem;
}

.researchCard {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr;
  padding: 0.5rem;
}

.viewCard {
  background-color: gray;
  padding: 0.5rem;
  border-radius: 0.3rem;
  margin: 0.3rem;
}

.viewCard:hover {
  cursor: pointer;
}
