@media only screen and (min-width: 180px) and (max-width: 667px) {
    html {
        font-size: 10px;
    }

    .dashboardContent {
        min-height: 100vh;
    }

    .logo {
        grid-area: 0 / 0 / 1 / 1;
        justify-self: start;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
    }

    .App-header {
        display: grid;
        grid-template: 1fr / 1fr;
        min-height: 30vh;
        justify-items: end;
        align-items: center;
    }

    .mainTitle {
        color: black;
        font-family: museoSlab, sans-serif;
        font-size: 2.2rem;
        margin: 0 1rem 0 1rem;
        text-align: right;
    }

    .break {
        display: none;
    }

    .break320 {
        display: none;
    }

    .mainHeadingBox {
        height: 25vh;
    }

    .downArrowIcon {
        right: 1rem;
        opacity: .1;
    }

    .systemDesignImage {
        width: 35rem;
        bottom: -4.5rem;
        right: -14rem;
    }

    .mobileAppsBox {
        display: grid;
        grid-template: 1fr 1fr / 1fr;
    }

    .appPlatforms {
        margin: -3rem auto;
    }

    .appPlatformBox {
        display: unset;
    }

    .iconBoxiOS {
        width: unset;
    }

    .iconBoxAndroid {
        width: unset;
    }

    .designContainer {
        padding-top: 3rem;
    }

    .logoBox img {
        width: 6rem;
        filter: unset;
    }

    .designLogos {
        display: grid;
        grid-template: 1fr 1.1fr / 1fr;
    }

    .trusteeTestimonialBox {
        grid-template: 0.5fr 1.2fr / 1fr;
    }

    .trusteeLogoBox img {
        width: 6rem;
        filter: none;
    }

    .tata img {
        width: 4rem;
        height: unset;
        padding-top: 1rem;
    }
    
    .expertiseContent {
        display: grid;
        grid-template: 1fr / 1fr;
        min-height: 40vh;
        margin-bottom: 2rem;
    }

    .responsiveLine {
        display: block;
    }

    .expertiseContentTitle {
        grid-area: 0 / 0 / 1 / 1;
        text-align: left;
        font-family: myriadText, sans-serif;
        margin-left: 3rem;

    }

    .webMockUpBox {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2.5rem 0;
    }

    .webMockUp {
        height: 22rem;
        width: 33rem;
    }

    .buttonStyle:hover {
        transform: scale(1.03);
    }

    .portfolioContainer {
        height: 80vh;
        width: 94.5vw;
        margin: 0 0.5rem;
        font-family: myriadText, sans-serif;
    }

    .accordion_ {
        margin: 1rem 0.5rem;
    }

    .tabBrandsContainer {
        display: grid;
        min-height: 20vh;
        grid-template: 1fr / 1fr 1fr 1fr 1fr;
        margin: 0 auto;
    }

    .logoInfo1, .logoInfo2, .logoInfo3, .logoInfo4 {
        display: none;
    }

    .accordion_title h3{
        font-size: 1.4rem;
    }

    .accordion_title p{
        font-size: 1.2rem;
    }

    footer .info {
        display: grid;
        grid-template: 1fr 1fr / 1fr;
        width: 100%;
    }

    .infoDetails h3 {
        font-family: MyriadSetProUltralight, sans-serif;
        font-size: 1rem;
        color: white;
        margin: 0 0 0 20%;
        padding: 0.5rem;
        width: 100%;
    }

    .infoDetails a {
        color: white;
        font-size: 1rem;
        font-family: myriadThin, sans-serif, sans-serif;
        margin: 0 0 0 20%;
        padding: 0.5rem;
        text-align: left;
    }

    .adminHeader {
        display: flex;
        margin: 0 auto;
        width: 50%;
        min-height: 15vh;
        justify-content: center;
        align-items: center;
        font-family: museoSlab, sans-serif;
    }

    .adminBtnContainer {
        margin: 2% auto;
        width: 80%;
    }

    .adminLoginContainer {
        width: 90%;
        margin: 10% auto;
    }

    .emailInput_InputStyle {
        width: 70%;
        height: 0.1rem;
        padding: 4%;
        border-radius: 0.6em;
        font-size: 0.9em;
        border: 2px solid darkturquoise;
        font-family: 'myriadText', sans-serif;
    }

    .passwordInput_InputStyle{
        width: 70%;
        height: 0.1rem;
        padding: 4%;
        border-radius: 0.6em;
        font-size: 0.9em;
        border: 2px solid darkturquoise;
        font-family: 'myriadText', sans-serif;
    }

    .dataCardsContainer {
        display: grid;
        grid-template: 1fr / 1fr;
        grid-auto-flow: row;
        margin: 2%;
        border: 1px solid #8c8b8b;
        border-radius: 0.4rem;
    }

    .adminPanelHeader {
        display: grid;
        grid-template: 1fr / 10fr 1fr;
    }

    .adminPanelDataContainer h2 {
        font-family: myriadText,sans-serif;
        margin: 4% 0 0 2%;
    }

    .adminPanelLogOutBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #903E63;
        min-height: 5.5vh;
    }

    /*----------------------------------- Hamburger Menu -----------------------------------*/

    a {
        text-decoration: none;
        color: #232323;

        transition: color 0.3s ease;
    }

    a:hover {
        color: tomato;
    }

    #menuToggle {
        display: block;
        position: absolute;
        top: 2rem;
        right: 2rem;

        z-index: 1;

        -webkit-user-select: none;
        user-select: none;
    }

    #menuToggle input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        right: -5px;

        cursor: pointer;

        opacity: 0; /* hide this */
        z-index: 2; /* and place it over the hamburger */

        -webkit-touch-callout: none;
    }

    /*
     * Just a quick hamburger
     */
    #menuToggle span {
        display: block;
        width: 2.7rem;
        height: 0.35rem;
        margin-bottom: 5px;
        position: relative;

        background: #903E63;

        z-index: 1;

        transform-origin: 4px 0;

        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        opacity 0.55s ease;
    }

    #menuToggle span:first-child {
        transform-origin: 0 0;
    }

    #menuToggle span:nth-last-child(2) {
        transform-origin: 0 100%;
    }

    /*
     * Transform all the slices of hamburger
     * into a crossmark.
     */
    #menuToggle input:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: #232323;
    }

    /*
     * But let's hide the middle one.
     */
    #menuToggle input:checked ~ span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    /*
     * Oh yeah and the last one should go the other direction
     */
    #menuToggle input:checked ~ span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
    }

    /*
     * Make this absolute positioned
     * at the top left of the screen
     */

    #menuToggle input:checked ~ #menu {
        width: 8rem;
        height: auto;
        opacity: 1;
        transform: scale(1.5);
    }

    #menuToggle input:checked ~ #menu li {
        opacity: 1;
    }

    #menu {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;

        margin: -100px 0 0 -10rem;
        padding: 12rem 1rem 1rem 2rem;

        background-color: white;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        border-radius: 2rem;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */

        -webkit-transition: width 1s, height 1s; /* For Safari 3.1 to 6.0 */
        transition: width 1s, height 1s;

    }

    #menu li {
        padding: 0.3rem 0;
        font-size: 0.9rem;
        display: block;
        opacity: 0;
        -webkit-transition: opacity 3s; /* For Safari 3.1 to 6.0 */
        transition: opacity 3s;
    }

    /*
     * And let's slide it in from the left
     */
    #menuToggle input:checked ~ ul {
        transform: none;
    }

    .luukitPrivacyPolicy {
        height: 100vh;
        overflow-y: scroll;
    }

    /* */
}


/* Iphone6/7/8 */
@media screen and (min-width: 375px) and (max-width: 375px) { 
    .systemDesignImage {
        width: 25rem;
        bottom: -3.2rem;
        right: -9rem;
    }
    
    .mobileAppsContainer {
        margin-left: -3rem;
    }
}

/* iphone5/se */
@media screen and (min-width: 320px) and (max-width: 320px) { 
    .systemDesignImage {
        width: 20rem;
        bottom: -2.7rem;
        right: -4.5rem;
    }
    
    .mobileAppsContainer {
        margin-left: -5.5rem;
    }
}

/* Pixel2xl*/
@media screen and (min-width: 411px) and (max-width: 411px) { 
    .systemDesignImage {
        width: 25rem;
        bottom: -3.1rem;
        right: -6rem;
    }
    
    .mobileAppsContainer {
        margin-left: -1rem;
    }
}

/* Galaxy*/
@media screen and (min-width: 360px) and (max-width: 360px) { 
    .systemDesignImage {
        width: 21rem;
        bottom: -2.8rem;
        right: -4.5rem;
    }
    
    .mobileAppsContainer {
        margin-left: -3.8rem;
    }
}

/* LG */
@media screen and (min-width: 384px) and (max-width: 384px) { 
    .systemDesignImage {
        width: 21rem;
        bottom: -2.8rem;
        right: -4.5rem;
    }
    
    .mobileAppsContainer {
        margin-left: -3.8rem;
    }
}


/* ipadMini */
@media screen and (min-width: 720px) and (max-width: 720px) {
    .mainHeadingBox {
        height: 50vh;
    }

    .downArrowIcon {
        right: 1rem;
        opacity: .1;
    }

    .systemDesignImage {
        width: 35rem;
        bottom: -4.5rem;
        right: -10rem;
    }

    .mobileAppsBox {
        display: grid;
        grid-template: 1fr 1fr / 1fr;
    }

    .appPlatforms {
        margin: -3rem auto;
    }

    .appPlatformBox {
        display: unset;
    }

    .iconBoxiOS {
        width: unset;
    }

    .iconBoxAndroid {
        width: unset;
    }

    .designContainer {
        padding-top: 3rem;
    }

    .logoBox img {
        width: 6rem;
        filter: unset;
    }

    .designLogos {
        display: grid;
        grid-template: 1fr 1.1fr / 1fr;
    }

    .trusteeTestimonialBox {
        grid-template: 0.5fr 1.2fr / 1fr;
    }

    .trusteeLogoBox img {
        width: 6rem;
    }

    .tata img {
        width: 4rem;
        height: unset;
        padding-top: 1.8rem;
    }
}

/* ipadPro */
@media screen and (min-width: 768px) and (max-width: 768px) {
    .mainHeadingBox {
        height: 45vh;
    }

    .mainTitle {
        padding-left: 3rem;
    }

    .downArrowIcon {
        right: 1rem;
        opacity: .1;
    }

    .systemDesignImage {
        width: 37rem;
        bottom: -4.8rem;
        right: -12rem;
    }

    .mobileAppsBox {
        display: grid;
        grid-template: 1fr 1fr / 1fr;
    }

    .appPlatforms {
        margin: -3rem auto;
    }

    .appPlatformBox {
        display: unset;
    }

    .iconBoxiOS {
        width: unset;
    }

    .iconBoxAndroid {
        width: unset;
    }

    .designContainer {
        padding-top: 3rem;
    }

    .logoBox img {
        width: 6rem;
        filter: unset;
    }

    .designLogos {
        display: grid;
        grid-template: 1fr 1.1fr / 1fr;
    }

    .trusteeTestimonialBox {
        grid-template: 0.5fr 1.2fr / 1fr;
    }

    .trusteeLogoBox img {
        width: 6rem;
    }

    .tata img {
        width: 4rem;
        height: unset;
        padding-top: 1.8rem;
    }
}

/* ipadPro10INCH */
@media screen and (min-width: 834px) and (max-width: 834px) {
    .mainHeadingBox {
        height: 50vh;
    }

    .downArrowIcon {
        right: 1rem;
        opacity: .1;
    }

    .systemDesignImage {
        width: 35rem;
        bottom: -4.5rem;
        right: -10rem;
    }

    .mobileAppsBox {
        display: grid;
        grid-template: 1fr 1fr / 1fr;
    }

    .appPlatforms {
        margin: -3rem auto;
    }

    .appPlatformBox {
        display: unset;
    }

    .iconBoxiOS {
        width: unset;
    }

    .iconBoxAndroid {
        width: unset;
    }

    .designContainer {
        padding-top: 3rem;
    }

    .logoBox img {
        width: 6rem;
        filter: unset;
    }

    .designLogos {
        display: grid;
        grid-template: 1fr 1.1fr / 1fr;
    }

    .trusteeTestimonialBox {
        grid-template: 0.5fr 1.2fr / 1fr;
    }

    .trusteeLogoBox img {
        width: 6rem;
    }

    .tata img {
        width: 4rem;
        height: unset;
        padding-top: 1.8rem;
    }
}


/* ipadPro */
@media screen and (min-width: 1024px) and (max-width: 1024px) {
    .mainHeadingBox {
        height: 40vh;
    }

    .mainTitle {
        padding-left: 7rem;
    }

    .downArrowIcon {
        right: 1rem;
        opacity: .1;
    }

    .systemDesignImage {
        width: 45rem;
        bottom: -5.9rem;
        right: -11rem;
    }

    .mobileAppsBox {
        display: grid;
        grid-template: 1fr / 1fr 1fr;
    }

    .appPlatformBox {
        display: unset;
    }

    .iphone img {
        width: 40rem;
        object-fit: contain;
        margin: -5rem 0 0 -4rem;
    }

    .iconBoxiOS {
        width: 40%;
    }

    .iconBoxAndroid {
        width: 40%;
    }

    .designContainer {
        padding-top: 3rem;
    }

    .logoBox img {
        width: 6rem;
        filter: unset;
    }

    .designLogos {
        display: grid;
        grid-template: 1fr 1.1fr / 1fr;
    }

    .trusteeTestimonialBox {
        grid-template: 0.5fr 1.2fr / 1fr;
    }

    .trusteeLogoBox img {
        width: 6rem;
    }

    .tata img {
        width: 4rem;
        height: unset;
        padding-top: 1.8rem;
    }
}