/* .marketSurveyContainer {
  justify-content: "centre";
} */

/*.App {*/
/*text-align: center;*/
/*}*/

/*.App-logo {*/
/*animation: App-logo-spin infinite 20s linear;*/
/*height: 40vmin;*/
/*}*/

/*.App-header {*/
/*background-color: #282c34;*/
/*min-height: 100vh;*/
/*display: flex;*/
/*flex-direction: column;*/
/*align-items: center;*/
/*justify-content: center;*/
/*font-size: calc(2px + 2vmin);*/
/*color: white;*/
/*}*/

@font-face {
  /*Myriad Set Pro Text*/
  font-family: "myriadText";
  src: url("./assets/fonts/myriadText.ttf");
}

@font-face {
  font-family: "myriadThin";
  src: url("./assets/fonts/myriadThin.ttf");
}

@font-face {
  font-family: "MyriadSetProUltralight";
  src: url("./assets/fonts/MyriadSetProUltralight.ttf");
}

@font-face {
  font-family: "myriadBold";
  src: url("./assets/fonts/myriadBold.ttf");
}

@font-face {
  font-family: "museoSlab";
  src: url("./assets/fonts/museoSlab.otf");
}

@font-face {
  font-family: "agencyGothic";
  src: url("./assets/fonts/agencyGothic.otf");
}

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */

.App {
  overflow: hidden;
}

.mainNav {
  padding-top: 0.5rem;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  z-index: 1;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.logo {
  grid-area: 0 / 0 / 1 / 1;
  justify-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4rem;
}

.topLogoImage {
  width: 4rem;
  height: 4rem;
  min-width: 2rem;
  min-height: 2rem;
}

.navigation {
  grid-area: 0 / 1 / 1 / 1;
  margin-right: 1.5rem;
}

#menuToggle {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr;
}

#menuToggle input {
  display: none;
}

#menuToggle input {
  display: none;
}

.mainNav ul {
  grid-area: 0 / 5 / 1 / 6;
  padding-top: 1rem;
}

.mainNav li,
a {
  display: inline;
  padding: 0.5rem;
  margin: 0.1rem;
  text-decoration: none;
  font-family: myriadText, sans-serif;
  font-size: 1.1rem;
  color: black;
}

.mainNav .selected li {
  color: #903e63;
  border-bottom: solid #903e63 0.07rem;
}

.dashboardContent {
  min-height: 100vh;
}

.App-header {
  display: grid;
  grid-template: 1fr / 1fr;
  min-height: 50vh;
  justify-items: end;
  align-items: center;
}

.mainHeadingBox {
  height: 50vh;
}

.mainHeading {
  grid-area: 0 / 0 / 1 / 1;
}

.mainTitle {
  color: #333333;
  font-family: museoSlab, sans-serif;
  font-size: 3.2rem;
  margin: 3rem;
  padding: 2rem;
}

.downArrowIconBox {
  position: relative;
  z-index: 1000;
}

.downArrowIcon {
  position: absolute;
  bottom: 0;
  right: 2rem;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  opacity: 0.2;
}

.downArrowIcon:hover {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
}

.upArrowIconBox {
  position: relative;
  z-index: 1000;
}

.upArrowIcon {
  position: absolute;
  bottom: -8rem;
  right: 2rem;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  opacity: 0.2;
}

.upArrowIcon:hover {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
}

.digital {
  color: #903e63;
}

.maroonUnderline {
  width: 12%;
  margin-top: -1rem;
}

.maroonUnderline hr {
  background-color: #ac175a;
  border: none;
  width: 100%;
  height: 0.2rem;
}

.maroonUnderline1 {
  width: 12%;
  margin-top: -1rem;
}

.maroonUnderline1 hr {
  background-color: #ac175a;
  border: none;
  width: 100%;
  height: 0.2rem;
}

.maroonUnderline2 {
  width: 10%;
  margin: 0 auto;
  padding-bottom: 1rem;
}

.maroonUnderline2 hr {
  background-color: #ac175a;
  border: none;
  height: 0.18rem;
  margin-top: -1rem;
}

.softwareEngineeringContainer {
  background-color: #9c3664;
  position: relative;
  overflow: hidden;
  padding: 5rem;
}

.softwareEngineeringContainer h4 {
  font-family: museoSlab;
  font-size: 2rem;
  color: #ffff;
}

.softwareEngineeringBox {
  display: grid;
  grid-template: 1fr 1fr / 1fr;
}

.softwareEngineeringBox > div {
  padding-bottom: 1rem;
}

.webApp {
  display: flex;
  align-items: center;
}

.webApp h5 {
  font-size: 1.3rem;
}

.iconBox {
  text-align: center;
  background-image: linear-gradient(to bottom right, #ecf8ff, #ede9ff);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
}

.iconBoxiOS {
  text-align: center;
  background-image: linear-gradient(to bottom right, #ecf8ff, #ede9ff);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
  width: 15%;
}

.iconBoxAndroid {
  text-align: center;
  background-image: linear-gradient(to bottom right, #ecf8ff, #ede9ff);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
  width: 15%;
}

.webApp {
  display: flex;
  align-items: center;
}

.iconBox img {
  width: 5rem;
  object-fit: contain;
}

.webApp h5 {
  margin-left: 3rem;
  padding-top: 3rem;
  font-family: myriadText;
  font-size: 1.8rem;
  color: #ffffff;
}

.systemDesignImage {
  position: absolute;
  bottom: -8rem;
  right: -14rem;
  width: 60rem;
  object-fit: contain;
}

.luukitIconBox {
  background-image: linear-gradient(
    to top left,
    #ffff 0%,
    #ffff 10%,
    #ffff 69%,
    #9c3664 70%,
    #9c3664 100%
  );
  padding: 1rem 0 1rem 5rem;
  display: flex;
  align-items: center;
}

.luukitIconBox h5 {
  margin-left: 3rem;
  padding-top: 3rem;
  font-family: myriadText;
  font-size: 1.4rem;
  color: #8d8d8d;
}

.mobileAppsContainer {
  padding: 2rem;
  background-image: linear-gradient(to bottom right, #fafafa, #fff8fb, #ffffff);
}

.mobileAppsBox {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
}

.iphone {
  /* text-align: right; */
}

.iphone img {
  width: 40rem;
  object-fit: contain;
  margin-top: -5rem;
}

.appPlatforms h4 {
  font-family: museoSlab;
  font-size: 2rem;
  color: #242424;
}

.appPlatforms h5 {
  font-family: myriadText;
  font-size: 2rem;
  color: #8d8d8d;
}

.appPlatformBox {
  display: flex;
  align-items: center;
}

.appPlatformBox h5 {
  margin-left: 1rem;
  font-family: myriadText;
  font-size: 1.4rem;
  padding-top: 3rem;
}

.last {
  margin-top: 3rem;
}

.designContainer {
  background-image: linear-gradient(to bottom left, #ffffff, #fafafa, #fff8fb);
  padding-top: 1rem;
}

.designLogos {
  display: grid;
  grid-template: 1fr / 2fr 1fr;
}

.logosContainer {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  grid-column-gap: 0.2rem;
  grid-row-gap: 0.2rem;
}

.designLogos h4 {
  font-family: museoSlab;
  font-size: 2rem;
  color: #242424;
  text-align: center;
}

.viewMoreLogos h4 a {
  font-family: myriadText;
  font-size: 1.2rem;
  font-style: italic;
  color: #707070;
}

.viewMoreLogos a:hover {
  color: rgb(65, 109, 0);
}

/* .brand1 {
    grid-area: 0 / 0 / 1 / 1;
}

.brand2 {
    grid-area: 0 / 1 / 1 / 2;
}

.brand3 {
    grid-area: 0 / 2 / 1 / 3;
}

.brand4 {
    grid-area: 0 / 3 / 1 / 4;
} */

.logoBox {
  text-align: center;
}

.brandLogo {
  height: 9rem;
  width: 6rem;
  object-fit: contain;
}

.logoBox img {
  height: 9rem;
  width: 5rem;
  object-fit: contain;
  filter: opacity(0.7);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.logoBox img:hover {
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
  filter: none;
}

.graphicBox {
  /* padding: 0.3rem; */
}

.graphicalCollateralBox img {
  height: 24rem;
  object-fit: contain;
}

.graphicalCollateralBox .carousel .slide {
  background: white;
}

/* TestimonialTrustee Box */
.trusteeTestimonialContainer {
  padding: 1rem;
}

.trusteeTestimonialContainer h4 {
  font-family: museoSlab, sans-serif;
  font-size: 2rem;
  color: #242424;
  text-align: center;
}

/*.trusteeTestimonialBox {*/
/*!*display: grid;*!*/
/*!*grid-template: 1fr / 1.5fr 1fr;*!*/
/*}*/

.trusteeContainer {
  width: 100%;
  display: grid;
  place-items: center;
  padding-top: 0.2rem;
}

.trusteeContainer h5 {
  font-family: myriadText;
  font-size: 1rem;
  color: #8d8d8d;
  text-align: center;
}

/*.trusteeInfoBox {*/
/*display: grid;*/
/*place-items: center;*/
/*background-color: #f2f2f2;*/
/*border-radius: 0.5rem;*/
/*}*/

/*.trusteeInfoBox {*/
/*display: grid;*/
/*place-items: center;*/
/*}*/

/*.trustedInfoLogoBox {*/
/*display: grid;*/
/*grid-template: 1fr / 1fr 1fr 1fr;*/
/*}*/

/*.trusteeLogoBox {*/
/*-webkit-transition: all .5s;*/
/*transition: all .5s;*/
/*}*/

/*.trusteeLogoBox img {*/
/*width: 6rem;*/
/*height: 6rem;*/
/*object-fit: contain;*/
/*}*/

.tata img {
  margin-top: 1.2rem;
}

/*.trusteeLogoBox img:hover {*/
/*-webkit-transition: all .5s;*/
/*transition: all .5s;*/
/*-webkit-transform:scale(1.1);*/
/*transform:scale(1.1);*/
/*}*/

.testimonialSection {
  padding: 0.2rem;
}

.testimonialSection h5 {
  font-family: myriadText;
  font-size: 1rem;
  color: #8d8d8d;
  text-align: center;
}

.testimonialCase {
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 0.5rem;
}

.attestantPhoto {
  display: flex;
  justify-content: space-between;
}

.attestantPhoto img {
  width: 4rem;
  object-fit: contain;
}

.attestantDetails {
  padding-top: 0.3rem;
}

.attestantDetails h6 {
  font-family: museoSlab;
  font-size: 1.1rem;
}

.attestantDetails p {
  font-family: myriadText;
  font-size: 0.9rem;
  color: #8d8d8d;
}

.attestantTestimon {
  padding-top: 0.3rem;
}

.attestantTestimony p {
  font-family: museoSlab, sans-serif;
}

.attestantRating {
  display: flex;
}

/*.trusteeContainer {*/
/*width: 100%;*/
/*}*/

/*.trusteeContainer h5 {*/
/*font-family: myriadText, sans-serif;*/
/*font-size: 1rem;*/
/*color: #8d8d8d;*/
/*text-align: center;*/
/*}*/

/*.trusteeInfoBox {*/
/*display: grid;*/
/*grid-template: 1fr / 1fr 1fr 1fr 1fr;*/
/*background-color: #f2f2f2;*/
/*border-radius: 0.5rem;*/
/*}*/

.trusteeCompaniesBox {
  width: 85%;
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  background-color: rgba(242, 242, 242, 0.41);
}

.trusteeCompaniesBox > div:first-child img {
  width: 8rem;
  object-fit: contain;
}

.trusteeCompaniesBox > div:nth-child(3) img {
  width: 6rem;
  object-fit: contain;
}

.trusteeCompaniesBox > div:last-child img {
  width: 9rem;
  object-fit: contain;
}

.trusteeCompaniesLogoBox {
  display: grid;
  place-items: center;
}

.trusteeCompaniesLogoBox img {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

#tataImg {
  width: 6rem;
  height: 6rem;
}

.trusteeCompaniesLogoBox img:hover {
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.pageHeading {
  display: grid;
  grid-template: 1fr / 1fr;
  min-height: 40vh;
  justify-items: end;
  align-items: center;
}

/* EXPERTISE */

.expertiseContent {
  display: grid;
  grid-template: 1fr / 1fr 2fr;
  min-height: 40vh;
  margin-bottom: 2rem;
}

.responsiveLine {
  display: none;
}

.expertiseContentTitle {
  grid-area: 0 / 0 / 1 / 1;
  text-align: center;
  font-family: myriadText, sans-serif;
}

.expertiseContentDescription {
  grid-area: 0 / 1 / 1 / 2;
  color: black;
  margin-bottom: 3rem;
}

.expertiseContentDescription p {
  font-family: myriadText, sans-serif;
  padding: 0 5rem;
  font-size: 1.2rem;
}

.expertiseContentDescription h2 {
  font-family: myriadText, sans-serif;
  padding: 2rem 5rem 0 5rem;
}

.expertiseContentDescription ul {
  font-family: myriadText, sans-serif;
  font-size: 1.1rem;
  padding: 0 7rem;
  color: #888888;
}

li {
  margin: 0.5rem;
}

.eventsContent {
  text-align: center;
  font-family: agencyGothic, sans-serif;
  letter-spacing: 0.5rem;
  margin-top: 10rem;
  color: #5e6263;
}

.careersContent {
  display: grid;
  grid-template: 1fr 20fr / 1fr;
  justify-items: center;
  margin: 0 2rem;
}

.careersContent h1 {
  font-family: museoSlab, sans-serif;
  font-size: 2.2rem;
}

.careersContent input,
textarea {
  font-family: myriadText, sans-serif;
  width: 100%;
  padding: 0.2rem 0.2rem;
  font-size: 1rem;
  border: none;
  border-bottom: #903e63 solid 1px;
}

#filePlaceholder {
  font-family: myriadText, sans-serif;
  font-size: 1rem;
  color: #a9a9a9;
}

.careerHeading {
  grid-area: 0 / 0 / 1 / 1;
}

.careerBody {
  grid-area: 1 / 0 / 2 / 1;
}

.cardSectionContainerStyle {
  margin-top: 4rem;
}

.cardSectionContainerStyle p {
  font-family: myriadBold, sans-serif;
  font-size: 1.1rem;
}

.CVPageTitle h1 {
  font-family: myriadText, sans-serif;
  margin: 2rem 1rem;
}

.CVCardSectionContainerStyle {
  margin: 0.5rem 1rem;
  background-color: #f4f4f4;
  padding: 0.5rem;
}

.CVCardSectionContainerStyle h3 {
  font-family: myriadText, sans-serif;
  font-size: 1.1rem;
}

.CVCardSectionContainerStyle p {
  font-family: myriadThin, sans-serif;
  font-size: 1.1rem;
}

.buttonStyle {
  position: relative;
  font-family: museoSlab, sans-serif;
  font-size: 1rem;
  background-color: greenyellow;
  padding: 0.5rem;
  width: 100%;
  border: none;
  margin-bottom: 5%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.buttonStyle:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.buttonStyle:hover {
  transform: scale(1.2);
}

.buttonStyle:hover:after {
  opacity: 1;
}

button:hover {
  cursor: pointer;
}

#careerConsent {
  font-size: 0.8rem;
  font-family: myriadThin, sans-serif;
  text-align: center;
}

#careerFieldError {
  font-size: 0.9rem;
  font-family: myriadText, sans-serif;
  text-align: center;
  color: maroon;
}

/* blinking cursor */

.blinking-cursor {
  font-weight: 100;
  font-size: 3rem;
  color: #2e3d48;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;

  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

/* Horizontal Lines */

hr.style11 {
  height: 4px;
  background: url("./assets/graphics/hr-11.png") repeat-x 0 0;
  border: 0;
  width: 95%;
  margin-top: 1rem;
}

hr.style14 {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -webkit-linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -moz-linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -ms-linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -o-linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
}

.portfolio {
  overflow: scroll;
  margin-bottom: 1rem;
}

.portfolio h1 {
  font-family: myriadThin, sans-serif;
}

.my-tablist {
  margin-top: 2rem;
  font-family: myriadText, sans-serif;
}

.portfolioContainer {
  height: 80vh;
  width: 90vw;
  margin: 0 0.5rem;
  font-family: myriadText, sans-serif;
}

.section {
  width: 100%;
  display: grid;
  grid-template: 1fr / 1fr 3fr;
}

.portfolioLogoBox {
  grid-area: 0 / 0 / 1 / 1;
  margin: 0.8rem 0.5rem 0 0;
  display: flex;
  justify-content: right;
}

.carouselContainer {
  height: 100%;
  width: 100%;
  margin-bottom: 3rem;
}

.portfolioSlider {
  padding: 0 5rem;
}

.accordion_ {
  width: 100%;
  margin: 1rem auto;
}

.accordion_ .description_container {
  display: flex;
  flex-direction: row;
}

/* ---------------------------------- WireFrames ----------------------------------*/

.wireFrameLogoBox {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.wireFrameBox {
  height: 100%;
  width: 100%;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wireFrame {
  height: 40rem;
  width: 60rem;
}

/* ---------------------------------- MockUps ----------------------------------*/

.webMockUpBox {
  grid-area: 0 / 1 / 1 / 2;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

.webMockUp {
  height: 45rem;
  width: 70rem;
}

/* ---------------------------------- TabBody -------------------------------------- */

.tabBrandsContainer {
  display: grid;
  min-height: 20vh;
  grid-template: 1fr / 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
  margin-right: 2rem;
  margin-left: 2rem;
}

.brand1:hover .logoInfo1 {
  opacity: 1;
  transition: 1s; /* A 1 second transition. */
  background-image: linear-gradient(to bottom right, #903e63, #61045f);
  border-radius: 1rem;
}

.logoInfo1 {
  width: 100%;
  height: 100%;
  position: relative;
  top: -100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Completely invisible. */
  border-radius: 1rem;
  color: white;
  font-family: myriadThin, sans-serif;
}

.brand2:hover .logoInfo2 {
  opacity: 1;
  transition: 1s; /* A 1 second transition. */
  background-image: linear-gradient(to bottom right, #903e63, #61045f);
  border-radius: 1rem;
}

.logoInfo2 {
  width: 100%;
  height: 100%;
  position: relative;
  top: -100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Completely invisible. */
  border-radius: 1rem;
  color: white;
  font-family: myriadThin, sans-serif;
}

.brand3:hover .logoInfo3 {
  opacity: 1;
  transition: 1s; /* A 1 second transition. */
  background-image: linear-gradient(to bottom right, #903e63, #61045f);
  border-radius: 1rem;
}

.logoInfo3 {
  width: 100%;
  height: 100%;
  position: relative;
  top: -100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Completely invisible. */
  border-radius: 1rem;
  color: white;
  font-family: myriadThin, sans-serif;
}

.brand4:hover .logoInfo4 {
  opacity: 1;
  transition: 1s; /* A 1 second transition. */
  background-image: linear-gradient(to bottom right, #903e63, #61045f);
  border-radius: 1rem;
}

.logoInfo4 {
  width: 100%;
  height: 100%;
  position: relative;
  top: -100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Completely invisible. */
  border-radius: 1rem;
  color: white;
  font-family: myriadThin, sans-serif;
}

/* -------------------------------------------------------------------------------------- */

.accordion_title {
  background-color: #f4f4f4;
  margin: 0 auto;
  color: #444;
  cursor: pointer;
  padding: 1.2rem;
  text-align: left;
  border: none;
}

.graphicShowButton {
  position: relative;
  font-family: museoSlab, sans-serif;
  font-size: 1rem;
  background-color: #903e63;
  color: white;
  padding: 0.8rem 0.5rem;
  width: 100%;
  border: none;
  margin-bottom: 5%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.graphicShowButton:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.graphicShowButton:hover {
  transform: scale(1.05);
}

.graphicShowButton:hover:after {
  opacity: 1;
}

footer {
  color: white;
  background-color: #5e6263;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

footer .info {
  display: grid;
  grid-template: 1fr / 1fr 2fr;
  width: 100%;
}

.footerCopyrightBox p {
  margin-top: 1rem;
}

.info .infoName {
  grid-area: 0 / 0 / 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: museoSlab, sans-serif;
}

.infoName h2 {
  color: #ffffff;
}

.info .infoDetails {
  grid-area: 0 / 1 / 1 / 2;
  background-color: #2e3d48;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
}

.infoDetails .title {
  font-size: 1rem;
  font-family: myriadBold, sans-serif;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
}

.infoDetails h3 {
  font-family: MyriadSetProUltralight, sans-serif;
  font-size: 1rem;
  color: white;
  margin: 0 0 0 35%;
  padding: 0.5rem;
  width: 100%;
}

.infoDetails a {
  color: white;
  font-size: 1rem;
  font-family: myriadThin, sans-serif, sans-serif;
  margin: 0 0 0 35%;
  padding: 0.5rem;
  text-align: left;
}

.infoDetails a:hover {
  color: greenyellow;
}

.infoAddress {
  grid-area: 0 / 0 / 1 / 1;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
}

.infoEmails {
  grid-area: 0 / 1 / 1 / 2;
  background-color: #903e63;
  display: flex;
  flex-direction: column;
}

.socialLinksContainer {
  /*display: flex;*/
  /*margin: 0 0 4% 0%;*/
  /*padding: 0;*/

  display: grid;
  place-items: center;
}

#socialLinks {
  margin: 0;
}

.socialLogo {
  height: 2rem;
  width: 2rem;
}

footer p {
  font-family: myriadText, sans-serif;
  font-size: 1rem;
  word-spacing: 0.1rem;
  text-align: center;
}

footer a {
  font-family: myriadText, sans-serif;
  font-size: 1rem;
  word-spacing: 0.1rem;
  color: #fff;
}

/* ------------------------------------------ ADMIN ---------------------------------------- */

.adminHeader {
  display: flex;
  margin: 0 auto;
  width: 20%;
  min-height: 15vh;
  justify-content: center;
  align-items: center;
  font-family: museoSlab, sans-serif;
}

.adminHeader h2 {
  text-align: center;
}

.adminBtnContainer {
  margin: 2% auto;
  width: 40%;
}

.adminLoginContainer {
  width: 50%;
  margin: 0 auto;
}

.emailInputContainerStyle {
  margin: 2% 5% 0 5%;
  display: grid;
  grid-template: 1fr / 1fr 3.5fr;
}

.emailInputLabelStyle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1.5% 2%;
  font-family: myriadText, sans-serif;
  font-size: 0.9rem;
}

.emailInputIconStyle {
  height: 0.5em;
  width: 1.5em;
}

.emailInput_InputContainerStyle {
  grid-area: 1 / 2 / 2 / 9;
  padding: 2%;
}

.emailInput_InputStyle {
  width: 70%;
  height: 0.1rem;
  padding: 3%;
  border-radius: 0.6em;
  font-size: 0.9em;
  border: 2px solid darkturquoise;
  font-family: "myriadText", sans-serif;
}

.passwordInputContainerStyle {
  margin: 2% 5% 0 5%;
  display: grid;
  grid-template: 1fr / 1fr 3.5fr;
}

.passwordInputLabelStyle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1.5% 2%;
  font-family: myriadText, sans-serif;
  font-size: 0.9rem;
}

.passwordInputIconStyle {
  height: 1.5em;
  width: 1.5em;
}

.passwordInput_InputContainerStyle {
  grid-area: 1 / 2 / 2 / 9;
  padding: 2%;
}

.passwordInput_InputStyle {
  width: 70%;
  height: 0.1rem;
  padding: 3%;
  border-radius: 0.6em;
  font-size: 0.9em;
  border: 2px solid darkturquoise;
  font-family: "myriadText", sans-serif;
}

.inputContainerStyle {
}

.inputLabelContainerStyle {
  padding-bottom: 1rem;
}

.inputLabelStyle {
  font-family: myriadText, "sans-serif";
}

.adminPanelHeader {
  display: grid;
  grid-template: 1fr / 20fr 1fr;
}

.adminPanelLogOutBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #903e63;
  min-height: 8vh;
}

.adminPanelLogOutBtn:hover {
  cursor: pointer;
}

.adminPanelDataContainer h2 {
  font-family: myriadText, sans-serif;
  margin: 2% 0 0 2%;
}

.adminPageErrorContainer {
  width: 50%;
  margin: 0 auto;
  background-color: darkred;
  border-radius: 0.4rem;
}

.adminPageErrorContainer p {
  text-align: center;
  padding: 1%;
  font-size: 0.8rem;
  font-family: myriadThin, sans-serif;
  color: #fff;
}

/* --------------------------------------------- APPLICATIONS -----------------------------------------------------*/

.dataCardsContainer {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
  margin: 2%;
  border: 1px solid #8c8b8b;
  border-radius: 0.4rem;
}

.dataCardsContainer h3 {
  color: #d0adbd;
}

.dateTitle {
  font-family: myriadText, sans-serif;
  font-size: 1.5rem;
  margin: 0 0 0 1.9rem;
  color: #805267;
}

.cvDate {
  color: greenyellow;
}

.appCard {
  margin: 2%;
  background-color: #282c34;
  font-family: myriadText, sans-serif;
  border-radius: 0.4rem;
  padding: 5%;
  color: #fff;
  cursor: pointer;
}

.noDataContainer {
  margin: 2%;
  font-family: myriadText, sans-serif;
}

.noDataContainer p {
  font-family: myriadText, sans-serif;
}

/* --------------------------------------------- LEGAL -----------------------------------------------------*/

.legal {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr;
  height: 100vh;
  justify-content: center;
  overflow: scroll;
}

.legal h1 {
  text-align: center;
  font-family: myriadText, sans-serif;
}

.legal h2 {
  text-align: center;
  font-family: myriadThin, sans-serif;
  color: greenyellow;
}

.legal_termAndConditions {
  background-color: #282c34;
  color: white;
}

.legal_privacyPolicy {
  background-color: #001529;
  color: white;
}

.legal_userGuidelines {
  background-color: black;
  color: white;
}

/* --------------------------------------------- LUUKIT PRIVACY POLICY -----------------------------------------------------*/

.luukitPrivacyPolicy {
  height: 100vh;
  overflow: scroll;
}

/* --------------------------------------------- LUUKIT TERMS & CONDITIONS -----------------------------------------------------*/

.luukItTermsAndConditions {
  height: 100vh;
}
